import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import GGFXImage from "../../modules/GGFXImage"
import "./OffplanStatmodule.scss"
import FormFields from "../../../static/forms/offplan_brochure.json"
import DefaultForm from "../forms/default-form-layout"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanStatmodule = ({ data, offplanData }) => {
  const [show, setShow] = useState(false)

  const brochure =
    offplanData?.property_brochure?.length > 0
      ? offplanData?.property_brochure[0]?.url
      : ""

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const formTitle = "Download the Brochure"

  return (
    <div className="payment-new-layout" id='new-layout-payment'>
      <div className="payment_block_component">
        <GGFXImage
          ImageSrc={data?.bg_image}
          altText={`${data?.title} banner`}
          imagetransforms={offplanData?.ggfx_results}
          renderer="bgImg"
          imagename={"off-plan-campaign.offplan_modules_bg_image.payment_image"}
          strapiID={offplanData?.strapi_id}
          className="banner-img"
        />
        <div className="overlay-bg"></div>

        <Container>
          <div className="payment-content-section">
            <h2>{data?.title}</h2>
            <div className="description">
              <ContentModule Content={data?.content?.data?.content} />
            </div>
            <div className="payment_info-container">
              {data?.payment_plan?.map(payment => {
                return (
                  <div className="payment-info-item">
                    <p className="percent_value">{payment?.payment_percent}</p>
                    <p className="center_item">{payment?.payment_name}</p>
                    <p className="right_item">{payment?.payment_time}</p>
                  </div>
                )
              })}
            </div>
            {brochure && (
              <a
                className="button button-filled-green"
                href="javascript:void(0)"
                onClick={handleModal}
              >
                Download Brochure
              </a>
            )}
          </div>

          <Modal
            show={show}
            // onHide={handleClose}
            backdrop="static"
            centered
            dialogClassName="modal-popup-form news_subscribe offplan-brochure"
          >
            <Modal.Body>
              <div className="popup-form-wrapper news_subscribe">
                <div className="close-modal" onClick={() => handleClose()}>
                  <i className="icon black-cancel-icon"></i>
                </div>
                <div>
                  <DefaultForm
                    sourceUrl={pageurl}
                    phone_id_value={"brochure_download-button"}
                    fields={FormFields}
                    prop_address={offplanData?.title1}
                    formTitle={formTitle}
                    brochureUrl={brochure}
                    to_email_id={
                      offplanData?.additional_email
                        ? offplanData?.additional_email +
                          ", hauswebsiteleads@gmail.com"
                        : "hauswebsiteleads@gmail.com"
                    }
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </div>
  )
}

export default OffplanStatmodule
