import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import OffplanDetailsBanner from "../components/OffplanDetailsBanner/OffplanDetailsBanner"
import OffplanDetailIcons from "../components/OffplanDetailIcons/OffplanDetailIcons"
import OffplanDetailGallery from "../components/OffplanDetailGallery/OffplanDetailGallery"
import OffplanColumnBlocks from "../components/OffplanColumnBlocks/OffplanColumnBlocks"
import AmmenitiesComponent from "../components/AmmenitiesComponent/AmmenitiesComponent"
import AccordianValuation from "../components/AccordianValuation/AccordianValuation"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import OffplanContact from "../components/OffplanContact/OffplanContact"
import OffplanStatmodule from "../components/OffplanStatmodule/OffplanStatmodule"
import StatOffplan from "../components/StatOffplan/StatOffplan"
import OffplanCardSlider from "../components/OffplanCardSlider/OffplanCardSlider"
import { Modal } from "react-bootstrap"
import FormFields from "../../static/forms/offplan_brochure.json"
import DefaultForm from "../components/forms/default-form-layout"
import useDeviceMedia from "../hooks/useDeviceMedia"
import SecondaryHeader from "../components/SecondaryHeader/SecondaryHeader"
import OffplanCampaignCards from "../components/OffplanCampaignCards/OffplanCampaignCards"

const OffplanCampaignOne = ({ data }) => {
  const offplanItems = data?.strapiOffPlanCampaign
  const offplanData = {
    ...offplanItems,
    banner_content: offplanItems?.banner_content?.data?.banner_content,
  }

  const [show, setShow] = useState(false)

  const brochure =
    offplanData?.property_brochure?.length > 0
      ? offplanData?.property_brochure[0]?.url
      : ""

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const whatsappData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]
  const pageModules = offplanData?.offplan_modules

  const moveToForm = () => {
    const getId = document.getElementById("offplan-contact-form")
    getId.scrollIntoView({ behavior: "smooth" })
  }

  const scrollToPayment = () => {
    const getId = document.getElementById("new-layout-payment")
    getId.scrollIntoView({ behavior: "smooth" })
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const formTitle = "Download the Brochure"

  const { isMobile } = useDeviceMedia()
  const whatsapp_text =
    "Hi, I've come across the haus and haus Group and would like further information from you"

  return (
    <div>
      <Layout offplanNewLayout footerClass="property-footer">
        <SecondaryHeader
          whatsapp_num={
            offplanData?.whatsapp ? offplanData?.whatsapp : "971505980269"
          }
          whatsapp_text={whatsapp_text}
        />

        <OffplanDetailsBanner offplanNewLayout offplanData={offplanData} />
        {pageModules?.map((item, index) => {
          return (
            <div>
              {item?.strapi_component === "page-modules.offplan-icon" &&
                item?.select_module === "property_info" && (
                  <OffplanDetailIcons
                    offplanNewLayout
                    offplanData={offplanData}
                    iconData={{ ...item, content: item.content.data.content }}
                  />
                )}
              {item?.strapi_component === "page-modules.global-module" &&
                item?.select_module === "gallery_slider" && (
                  <OffplanDetailGallery
                    imageData={offplanData?.images}
                    offplanData={offplanData}
                    offplanNewLayout
                  />
                )}

              {item?.strapi_component === "page-modules.image-content" &&
                item?.left_right_block?.length > 0 && (
                  <OffplanColumnBlocks
                    data={item}
                    offplanData={offplanData}
                    offplanNewLayout
                  />
                )}
              {item?.strapi_component === "page-modules.offplan-icon" &&
                item?.select_module === "amenities" && (
                  <AmmenitiesComponent ammentiesData={item} offplanNewLayout />
                )}
              {item?.strapi_component === "page-modules.primary-module" && (
                <OffplanCampaignCards data={item} offplanData={offplanData} />
              )}
              {item?.strapi_component === "page-modules.payment-info" && (
                <OffplanStatmodule data={item} offplanData={offplanData} />
              )}

              {item?.strapi_component === "page-modules.offplan-icon" &&
                item?.select_module === "stats" && (
                  <StatOffplan data={item} offplanData={offplanData} />
                )}

              {item?.strapi_component === "page-modules.accordion" && (
                <AccordianValuation
                  add_toggle={item?.add}
                  title={item?.title}
                  content={item?.content}
                  valuation={true}
                  accordianPage="offplanlayout-new-accordion"
                />
              )}
              {item?.strapi_component === "components.title-desc" && (
                <ContentComponent data={item} layout="offplan-layout_newtext" />
              )}
              {item?.strapi_component === "page-modules.custom-modules" &&
                item?.select_module === "offplan_campaign_form" && (
                  <OffplanContact
                    offplanData={{
                      ...offplanData,
                      form_title: item?.title,
                      form_content: item?.content?.data?.content,
                    }}
                    whatsappData={whatsappData}
                    offplanNewLayout
                  />
                )}
            </div>
          )
        })}
        <OffplanCardSlider offplanData={offplanData} />
        <div className="offplan-new-btn-wrappers d-flex">
          <button
            onClick={() => moveToForm()}
            aria-label="Register Interest"
            className="button button-filled-green"
          >
            {!isMobile && <i className="icon black-mail" />}
            <span>{"Register Interest"}</span>
          </button>
          <button
            onClick={() => handleModal()}
            aria-label="Brochure"
            className="button button-filled-green d-none d-md-flex"
          >
            <i className="icon brochure-black-icon" />
            <span>{"Brochure"}</span>
          </button>
          <button
            onClick={() => scrollToPayment()}
            aria-label="Payment Plans"
            className="button button-filled-green"
          >
            {!isMobile && <i className="icon payment-icon" />}
            <span>{"Payment Plans"}</span>
          </button>
        </div>
        <Modal
          show={show}
          // onHide={handleClose}
          backdrop="static"
          centered
          dialogClassName="modal-popup-form news_subscribe offplan-brochure"
        >
          <Modal.Body>
            <div className="popup-form-wrapper news_subscribe">
              <div className="close-modal" onClick={() => handleClose()}>
                <i className="icon black-cancel-icon"></i>
              </div>
              <div>
                <DefaultForm
                  sourceUrl={pageurl}
                  phone_id_value={"brochure_download-button"}
                  fields={FormFields}
                  prop_address={offplanData?.title1}
                  formTitle={formTitle}
                  brochureUrl={brochure}
                  to_email_id={
                    offplanData?.additional_email
                      ? offplanData?.additional_email +
                        ", hauswebsiteleads@gmail.com"
                      : "hauswebsiteleads@gmail.com"
                  }
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    </div>
  )
}

export default OffplanCampaignOne

export const query = graphql`
  query ($page_id: Int) {
    strapiOffPlanCampaign(strapi_id: { eq: $page_id }) {
      area
      banner_content {
        data {
          banner_content
        }
      }
      images {
        url
      }
      property_brochure {
        url
      }
      ggfx_results {
        src_cftle
        transforms {
          url
          transform
          format
        }
      }

      banner_title
      expected_completion_date
      district
      developer
      crm_id
      createdAt
      latitude
      longitude
      phone_number
      price
      publish
      slug1
      strapi_id
      title1
      town
      updatedAt
      whatsapp
      offplan_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          id
          title
          content {
            data {
              content
            }
          }
          add {
            content {
              data {
                content
              }
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE {
          strapi_component
          title
          items {
            title
            sub_title
            image {
              url
            }
            video_url
            content {
              data {
                content
              }
            }
            cta {
              link_type
              title
              custom_link
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          id
          strapi_component
          title
          description {
            data {
              description
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          strapi_component
          select_module
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          id
          title
          content {
            data {
              content
            }
          }
          add {
            title
            content {
              data {
                content
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          id
          title
          content {
            data {
              content
            }
          }
          strapi_component
          select_module
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          strapi_component
          left_right_block {
            layout
            title
            video_url
            cta_label
            menu {
              slug
              strapi_parent {
                slug
              }
            }
            description {
              data {
                description
              }
            }
            image {
              url
            }
            longitude
            latitude
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PAYMENT_INFO {
          id
          title
          strapi_component
          content {
            data {
              content
            }
          }
          bg_image {
            url
          }
          payment_plan {
            payment_time
            payment_percent
            payment_name
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_OFFPLAN_ICON {
          id
          strapi_component
          select_module
          title
          content {
            data {
              content
            }
          }
          icon_list {
            heading
            image {
              url
            }
            sub_content {
              data {
                sub_content
              }
            }
            ctas {
              title
              link_type
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              custom_link
            }
          }
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          offplan_whatsapp_msg
        }
      }
    }
  }
`
